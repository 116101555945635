.form-selection-container {
    margin-left: 10px;
}

.form-response-container {
    margin-top: 10px;
    margin-left: 10px;
}

.form-buttons-form-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    margin-left: 30px;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.form-buttons-form-selection:hover {
    background-color: #ede5e8;
}