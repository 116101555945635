.form-attribute-container {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: inherit;
    padding: 10px 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-attribute-container:hover {
    background-color: #f0f0f0;
}

.questions-container {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: inherit;
    padding: 10px 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s;
}

.questions-container:hover {
    background-color: #f0f0f0;
}

.field-attribute-container-in-form {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: inherit;
    transition: background-color 0.3s;
    padding: 10px 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field-attribute-container-in-form:hover {
    background-color: rgb(0 0 0 / 10%);
}

.question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.expanded-icon {
    transition: transform 0.3s;
}

.expanded {
    transform: rotate(180deg);
}
