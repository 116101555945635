.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-repeat: "repeat";
    background-size: 150px 150px;
    background-image: url("../assets/images/background4.png");
}

.site-header {
    background-image: url("https://birdshot.tamu.edu/wp-content/uploads/sites/303/2023/12/Glockenbronze.jpg");
    box-shadow: inset 0 0 25em 0 rgba(0 0 0 / 85%);
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.site-title a,
.site-title a:hover,
.site-title a:focus {
    color: #eee;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.5;

}

.title-area {
    display: flex;
    align-items: center;
}

.site-title {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    color: white;
    font-size: 24px;
    text-decoration: none;
}

.header-widget-area {
    display: flex;
    align-items: center;
}

#site-logo img {
    max-width: 350px;
    height: auto;

    @media (max-width: 750px) {
        width: max(50px, 15vw);
        height: auto;
    }
}

.site-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:visible;
    height: max(100%, 100vh);
}

.body-title {
    color: #50011a;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 600;
}

.login-response {
    color: #50011a;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.5;
    font-size: 25px;
}

.server-error-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.link {
    color: #50011a;
}

.dropdown-toggle::after {
    display: none !important;
}


.datascribe-logo {
    height: 83px;

    @media (max-width: 750px) {
        height: auto;
        width: max(50px, 15vw);
    }
}

.registeration-details-container {
    @media (min-height: 500px) {
        flex: 1;
        height: max(50vh, 625px);
    }
}