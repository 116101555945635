:root {
    --window-height: 100vh;
}

.table-container {
    max-height: min(calc(0.35 * var(--window-height)), 500px);
    /* 35% of window height or 500px, whichever is less */

    overflow-y: auto;
    margin: 20px;
}

.curved-table {
    border-radius: 10px;
    overflow: hidden;
}
