.field-attribute-container {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: inherit;
    transition: background-color 0.3s;
    padding: 10px 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field-attribute-container:hover {
    background-color: #f0f0f0;
}

.field-list-item-container {
    font-size: 14px;
    border-radius: 5px;
    padding: 5px;
    background-color: inherit;
    margin-left: 25px;
    transition: background-color 0.3s;
}

.field-list-item-container:hover {
    background-color: rgb(0 0 0 / 10%);
}