.profile-picture {
    width: 175px;
    height: 175px;

    /* Makes the picture circular */
    border-radius: 50%;

    @media (max-width: 750px) {
        width: 125px;
        height: 125px;
    }
}

.profile-section {
    display: flex;
    flex-direction: row;
    padding: 20px;

    @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    }
}

.profile-info {
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: 750px) {
        padding-top: 20px;
    }
}